var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('b-card',{staticClass:"mt-4 shadow-sm card-rounded",attrs:{"border-variant":"light"}},[_c('b-table',{staticClass:"mb-0",attrs:{"busy":_vm.loading,"current-page":_vm.currentPage,"fields":_vm.fields,"head-variant":'light',"items":_vm.qres,"per-page":0,"fixed":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v(_vm._s(_vm.$t("loading")))])],1)]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('router-link',{style:(data.item.status.toUpperCase() === 'UNKNOWN'
              ? 'pointer-events: none; color:gray;'
              : ''),attrs:{"to":'/admin/qres/' + data.item.qreId}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(url)",fn:function(data){return [_c('a',{style:(data.item.status.toUpperCase() !== 'RUNNING'
              ? 'pointer-events: none; color:gray;'
              : ''),attrs:{"href":data.item.url,"target":"_blank"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"terminal"}}),_vm._v(" "+_vm._s(_vm.$t("qres.openNotebook"))+" ")],1)]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":_vm.statusVariant(data.item.status)}},[_vm._v(" "+_vm._s(_vm.statusText(data.item.status))+" ")])]}},{key:"cell(memory)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.byteToValueFormatter(data.item.memoryInBytes, true))+" ")]}},{key:"cell(cores)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.coreFormatter(data.item.cores))+" ")]}},{key:"cell(gpuIcon)",fn:function(data){return [(data.item.gpus.length > 0)?_c('GpuIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"color":data.item.gpus.some(function (el) { return el.status === 'BROKEN'; })
              ? '#fa5c7c'
              : '#6FBF94',"height":25,"title":data.item.gpus.some(function (el) { return el.status === 'BROKEN'; })
              ? 'GPU is broken'
              : 'GPU is connected',"width":25}}):_vm._e()]}},{key:"cell(empty)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","pill":"","size":"sm"},on:{"click":function($event){return _vm.goToEditQre(data.item.qreId)}}},[_c('b-icon-pencil')],1)],1)]}}])}),(_vm.total > _vm.pageSize)?_c('b-pagination',{staticClass:"mb-0 mt-3",attrs:{"per-page":_vm.pageSize,"total-rows":_vm.total,"align":"right","first-text":"First","last-text":"Last","next-text":"Next","prev-text":"Prev"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),(_vm.qres.length === 0 && !_vm.loading)?_c('b-card-text',{staticClass:"text-center my-4"},[_vm._v(" "+_vm._s(_vm.$t("qres.emptyTable"))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }