<template>
  <div class="mt-4">
    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-table
        :busy="loading"
        :current-page="currentPage"
        :fields="fields"
        :head-variant="'light'"
        :items="qres"
        :per-page="0"
        class="mb-0"
        data-cy="qres-table"
        fixed
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>{{ $t("loading") }}</strong>
          </div>
        </template>

        <template #cell(name)="data">
          <router-link
            :style="
              data.item.status.toUpperCase() === 'UNKNOWN'
                ? 'pointer-events: none; color:gray;'
                : ''
            "
            :to="'/admin/qres/' + data.item.qreId"
          >
            {{ data.value }}
          </router-link>
        </template>

        <template #cell(url)="data">
          <a
            :data-cy="`open-qre-notebook-${data.item.name}`"
            :href="data.item.url"
            :style="
              data.item.status.toUpperCase() !== 'RUNNING'
                ? 'pointer-events: none; color:gray;'
                : ''
            "
            target="_blank"
          >
            <b-icon class="mr-2" icon="terminal" />
            {{ $t("qres.openNotebook") }}
          </a>
        </template>

        <template #cell(status)="data">
          <b-badge
            :data-cy="`qre-status-table-badge-${data.item.name}`"
            :variant="statusVariant(data.item.status)"
          >
            {{ statusText(data.item.status) }}
          </b-badge>
        </template>

        <template #cell(memory)="data">
          {{ byteToValueFormatter(data.item.memoryInBytes, true) }}
        </template>

        <template #cell(cores)="data">
          {{ coreFormatter(data.item.cores) }}
        </template>

        <template #cell(gpuIcon)="data">
          <GpuIcon
            v-if="data.item.gpus.length > 0"
            v-b-tooltip.hover
            :color="
              data.item.gpus.some((el) => el.status === 'BROKEN')
                ? '#fa5c7c'
                : '#6FBF94'
            "
            :height="25"
            :title="
              data.item.gpus.some((el) => el.status === 'BROKEN')
                ? 'GPU is broken'
                : 'GPU is connected'
            "
            :width="25"
          />
        </template>

        <template #cell(empty)="data">
          <div class="d-flex justify-content-end">
            <b-button
              class="ml-2"
              variant="primary"
              pill
              size="sm"
              @click="goToEditQre(data.item.qreId)"
            >
              <b-icon-pencil />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="total > pageSize"
        v-model="currentPage"
        :per-page="pageSize"
        :total-rows="total"
        align="right"
        class="mb-0 mt-3"
        first-text="First"
        last-text="Last"
        next-text="Next"
        prev-text="Prev"
      />

      <b-card-text
        v-if="qres.length === 0 && !loading"
        class="text-center my-4"
      >
        {{ $t("qres.emptyTable") }}
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import GpuIcon from "@/components/Icons/GpuIcon.vue";
import { byteToValueFormatter, coreFormatter } from "@/util/formatters";

export default {
  components: { GpuIcon },
  name: "QREs",
  data() {
    return {
      qres: [],
      currentPage: 1,
      total: null,
      pageSize: 10,
      fields: [
        {
          key: "name",
          label: this.$i18n.t("name"),
          tdClass: "align-middle qre-name",
        },
        {
          key: "url",
          label: this.$i18n.t("qres.notebook"),
          tdClass: "align-middle",
        },
        {
          key: "status",
          label: this.$i18n.t("status"),
          thStyle: { width: "150px", minWidth: "150px !important" },
          tdClass: "align-middle",
        },
        {
          key: "cores",
          label: "Cores",
          thStyle: { width: "100px", minWidth: "100px !important" },
          tdClass: "align-middle",
        },
        {
          key: "memory",
          label: "Memory",
          thStyle: { width: "100px", minWidth: "100px !important" },
          tdClass: "align-middle",
        },
        {
          key: "gpuIcon",
          label: "GPUs",
          thStyle: { width: "100px", minWidth: "100px !important" },
          tdClass: "align-middle",
        },
        {
          key: "empty",
          label: "",
          thStyle: { width: "50px", minWidth: "50px !important" },
          tdClass: "align-middle",
        },
      ],
      loading: true,
    };
  },
  mounted() {
    this.getQres();
  },
  methods: {
    coreFormatter,
    byteToValueFormatter,
    goToEditQre(id) {
      this.$router.push({
        path: `/admin/qres/${id}`,
        query: { node: this.$route.params.id },
      });
    },

    async getQres() {
      this.loading = true;

      try {
        const {
          data: { content, page },
        } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_VERSION_URL}/agents/${this.$route.params.id}/qres`,
          {
            params: {
              page: this.currentPage - 1,
              pageSize: this.pageSize,
            },
          }
        );
        this.qres = content;
        this.total = page.totalElements;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },

    statusVariant(status) {
      switch (status.toUpperCase()) {
        case "RUNNING":
          return "success";
        case "STOPPED":
          return "danger";
        case "TRANSITIONING":
          return "warning";
        case "UNKNOWN":
        default:
          return "secondary";
      }
    },

    statusText(status) {
      return status.toUpperCase();
    },
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getQres();
      },
    },
  },
};
</script>

<style>
.qre-name {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
}
</style>
