<template>
  <div class="mt-4">
    <div class="my-4 d-flex align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="bar-chart-fill" />
        {{ node.name }}
      </h1>
      <b-button class="mr-2" variant="primary" pill @click="$router.go(-1)">
        <b-icon-arrow90deg-left class="mr-2" />
        {{ $t("back") }}
      </b-button>
    </div>

    <b-overlay :opacity="0.95" :show="loading" rounded="sm">
      <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <p class="mb-0 card-info-2">{{ $t("status") }}:</p>
          </div>

          <b-badge :variant="statusVariant(node.status)">
            {{ statusText(node.status) }}
          </b-badge>
        </div>
      </b-card>
    </b-overlay>

    <b-row class="mt-4">
      <b-col lg="3" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">
                {{ $t("singleNode.totalCores") }}
              </p>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0" data-cy="single-node-total-cores">
                {{ coreFormatter(resources.totalCores) }}
              </p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col lg="3" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">
                {{ $t("singleNode.totalMemory") }}
              </p>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0" data-cy="single-node-total-memory">
                {{ byteToValueFormatter(resources.totalMemoryInBytes, true) }}
              </p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col lg="3" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">
                {{ $t("singleNode.totalStorage") }}
              </p>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0" data-cy="single-node-total-storage">
                {{ byteToValueFormatter(resources.totalStorageInBytes, true) }}
              </p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col lg="3" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">
                {{ $t("singleNode.totalGPUs") }}
              </p>
            </div>
            <div
              v-if="resources.gpus.some((el) => el.status === 'BROKEN')"
              v-b-tooltip:hover.bottom
              class="mt-2"
              :title="$t('singleNode.hasBrokenGpus')"
            >
              <p class="card-info mb-0" data-cy="single-node-total-gpus">
                {{ resources.gpus.length }}
                <span class="text-danger"> * </span>
              </p>
            </div>

            <div v-else class="mt-2">
              <p class="card-info mb-0" data-cy="single-node-total-gpus">
                {{ resources.gpus.length }}
              </p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <b-overlay :opacity="0.95" :show="loading" rounded="sm">
      <b-card border-variant="light" class="shadow-sm card-rounded mt-4">
        <div class="d-flex align-items-center justify-content-between">
          <p class="mb-0 custom-card-title">
            {{ $t("singleNode.coresUsage") }}
          </p>
        </div>
        <div class="mt-2 mb-4">
          <b-progress
            v-b-tooltip.hover
            :max="resources.totalCores"
            :title="
              coreFormatter(resources.totalCores - resources.usedCores) +
              ' ' +
              $t('singleNode.free')
            "
            height="2rem"
          >
            <b-progress-bar :value="resources.usedCores" class="cores-used" />
            <div class="progress-bar-title" data-cy="single-node-used-cores">
              {{ coreFormatter(resources.usedCores) }}
              {{ $t("singleNode.used") }}
            </div>
          </b-progress>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <p class="mb-0 custom-card-title">
            {{ $t("singleNode.memoryUsage") }}
          </p>
        </div>
        <div
          v-b-tooltip.hover
          :title="
            byteToValueFormatter(
              resources.totalMemoryInBytes - resources.usedMemory,
              true
            ) +
            ' ' +
            $t('singleNode.free')
          "
          class="mt-2 mb-4"
        >
          <b-progress :max="resources.totalMemoryInBytes" height="2rem">
            <b-progress-bar :value="resources.usedMemory" class="memory-used" />
            <div class="progress-bar-title" data-cy="single-node-used-memory">
              {{ byteToValueFormatter(resources.usedMemory) }}
              {{ $t("singleNode.used") }}
            </div>
          </b-progress>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <p class="mb-0 custom-card-title">
            {{ $t("singleNode.storageUsage") }}
          </p>
        </div>
        <div class="mt-2">
          <b-progress
            v-b-tooltip.hover
            :max="resources.totalStorageInBytes"
            :title="
              byteToValueFormatter(
                resources.totalStorageInBytes - resources.usedStorage,
                true
              ) +
              ' ' +
              $t('singleNode.free')
            "
            height="2rem"
          >
            <b-progress-bar
              :value="resources.usedStorage"
              class="storage-used"
            />
            <div class="progress-bar-title" data-cy="single-node-used-storage">
              {{ byteToValueFormatter(resources.usedStorage) }}
              {{ $t("singleNode.used") }}
            </div>
          </b-progress>
        </div>
      </b-card>
    </b-overlay>

    <SingleNodeQres />

    <div class="mt-4">
      <b-card
        border-variant="light"
        class="mt-4 shadow-sm card-rounded wrapper"
      >
        <b-table
          :busy="loadingQres"
          :fields="fields"
          :filter="criteria"
          :filter-function="filterTable"
          :head-variant="'light'"
          :items="coresArray"
          :tbody-tr-class="rowColor"
          class="tableBody"
          data-cy="cores-qres-table"
          responsive
          sticky-header
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("loading") }}</strong>
            </div>
          </template>

          <template #cell(core)="data">
            {{ data.value }}
          </template>

          <template #cell(qres)="data">
            <div class="d-flex link-gap flex-wrap">
              <b-link
                v-for="qre in data.value"
                :key="qre.id"
                :to="'/admin/qres/' + qre.id"
                class="d-flex align-items-center"
              >
                <b-icon class="mr-1" icon="server" />
                {{ qre.name }}
              </b-link>
            </div>
          </template>
        </b-table>

        <b-form-checkbox
          v-if="!loadingQres"
          :aria-invalid="criteria !== ''"
          class="switch-button"
          size="md"
          switch
          @change="toggleTableFilter"
        >
          {{ $t("singleNode.unusedCores") }}
        </b-form-checkbox>
      </b-card>
    </div>

    <div class="mt-4">
      <b-card
        v-if="gpusArray.length > 0"
        border-variant="light"
        class="mt-4 shadow-sm card-rounded wrapper"
      >
        <b-table
          :busy="loadingQres"
          :fields="gpuFields"
          :filter="criteriaGpu"
          :filter-function="filterTable"
          :head-variant="'light'"
          :items="gpusArray"
          :tbody-tr-class="rowColor"
          data-cy="gpus-qres-table"
          responsive
          sticky-header
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("loading") }}</strong>
            </div>
          </template>

          <template #cell(gpu)="data">
            <b-icon-exclamation-circle-fill
              v-if="data.item.status === 'BROKEN'"
              v-b-tooltip.hover.top
              class="mr-1"
              :title="$t('singleNode.brokenGpu')"
              variant="danger"
            />
            {{ data.value }}
          </template>

          <template #cell(qres)="data">
            <div class="d-flex link-gap flex-wrap">
              <b-link
                v-for="qre in data.value"
                :key="qre.id"
                :to="'/admin/qres/' + qre.id"
                class="d-flex align-items-center"
              >
                <b-icon class="mr-1" icon="server" />
                {{ qre.name }}
              </b-link>
            </div>
          </template>
        </b-table>

        <b-form-checkbox
          v-if="!loadingQres"
          :aria-invalid="criteriaGpu !== ''"
          class="switch-button"
          size="md"
          switch
          @change="toggleTableFilterGpus"
        >
          {{ $t("singleNode.unusedGpus") }}
        </b-form-checkbox>
      </b-card>
    </div>
  </div>
</template>

<script>
import SingleNodeQres from "./SingleNodeQres.vue";
import { byteToValueFormatter, coreFormatter } from "@/util/formatters";

export default {
  name: "SingleNode",
  components: {
    SingleNodeQres,
  },
  data() {
    return {
      node: { status: "UNDEFINED" },
      resources: { usedCores: 0, usedStorage: 0, usedMemory: 0, gpus: [] },
      loading: true,
      loadingQres: true,
      totalCores: 0,
      coresArray: [],
      fields: [
        {
          key: "core",
          label: "Cores",
          thStyle: { width: "100px", minWidth: "100px !important" },
          tdClass: "align-middle",
        },
        { key: "qres", label: "Qres", tdClass: "align-middle" },
      ],
      criteria: "",
      criteriaGpu: "",
      gpusArray: [],
      gpuFields: [
        {
          key: "gpu",
          label: "GPUs",
          thStyle: { width: "400px", minWidth: "400px !important" },
          tdClass: "align-middle",
        },
        { key: "qres", label: "Qres", tdClass: "align-middle" },
      ],
    };
  },

  async mounted() {
    await this.getNode();
    await this.getQres();
  },

  methods: {
    coreFormatter,
    byteToValueFormatter,
    async getNode() {
      this.loading = true;
      try {
        const { data: node } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/agents/${this.$route.params.id}`
        );

        const { data: resources } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/agents/${this.$route.params.id}/hardware/resources`
        );

        this.node = node;

        this.resources = resources;
        this.resources.usedCores =
          resources.totalCores - resources.availableCores;
        this.resources.usedMemory =
          resources.totalMemoryInBytes - resources.availableMemoryInBytes;
        this.resources.usedStorage =
          resources.totalStorageInBytes - resources.availableStorageInBytes;
        this.resources.gpus = resources.gpuResourceResponses;

        this.totalCores = resources.totalCores;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async getQres() {
      this.loadingQres = true;
      try {
        const {
          data: { content: qres },
        } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_VERSION_URL}/agents/${this.$route.params.id}/qres`,
          {
            params: {
              page: 0,
              pageSize: 1000000,
            },
          }
        );

        for (let coreNumber = 0; coreNumber < this.totalCores; coreNumber++) {
          this.coresArray.push({
            core: "Core " + coreNumber,
            qres: [],
          });
        }

        this.resources.gpus.forEach((el) => {
          this.gpusArray.push({
            id: el.id,
            gpu: el.name,
            status: el.status,
            qres: [],
          });
        });

        qres.forEach((qre) => {
          if (qre.status === "RUNNING") {
            if (qre.gpus.length > 0) {
              qre.gpus.forEach((gpu) => {
                const found = this.gpusArray.findIndex(
                  (el) => el.id === gpu.id
                );

                if (found !== undefined && found !== -1) {
                  this.gpusArray[found].qres.push({
                    name: qre.name,
                    id: qre.qreId,
                  });
                }
              });
            }

            if (qre.cpuSetCpus !== "") {
              // check if splitCpus contains ranges
              qre.cpuSetCpus.split(",").forEach((cores) => {
                // if range
                if (cores.includes("-")) {
                  for (
                    let i = parseInt(cores.split("-")[0]);
                    i <= parseInt(cores.split("-")[1]);
                    i++
                  ) {
                    this.coresArray[i].qres.push({
                      name: qre.name,
                      id: qre.qreId,
                    });
                  }
                } else {
                  // else just push values to the array
                  this.coresArray[parseInt(cores)].qres.push({
                    name: qre.name,
                    id: qre.qreId,
                  });
                }
              });
            }
          }
        });
      } catch (err) {
        console.log(err);
        if (err.response) console.log(err.response);
      } finally {
        this.loadingQres = false;
      }
    },

    statusVariant(status) {
      switch (status.toUpperCase()) {
        case "UP":
          return "success";
        case "NOT_REACHABLE":
          return "danger";
        case "IMAGE_MISSING":
          return "warning";
        case "UNKNOWN":
        default:
          return "secondary";
      }
    },

    statusText(status) {
      return status.toUpperCase();
    },

    rowColor(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "BROKEN") {
        return "table-secondary";
      }
      if (item.qres.length === parseInt(process.env.VUE_APP_CORES_GREEN))
        return "table-success";
      if (item.qres.length === parseInt(process.env.VUE_APP_CORES_YELLOW))
        return "table-warning";
      if (item.qres.length >= parseInt(process.env.VUE_APP_CORES_RED))
        return "table-danger";
    },

    filterTable(row, filter) {
      return row.qres <= filter;
    },

    toggleTableFilter() {
      this.criteria = this.criteria === "0" ? "" : "0";
    },
    toggleTableFilterGpus() {
      this.criteriaGpu = this.criteriaGpu === "0" ? "" : "0";
    },
  },
};
</script>

<style scoped>
.card-info {
  font-size: 1.5rem;
  font-weight: 700;
  color: #6c757d;
  min-height: 36px;
}

.card-info-2 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #6c757d;
}

.custom-card-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  color: #7e868b;
}

.cores-used {
  background-color: #99dbd7;
  color: #6c757d;
  font-size: 1rem;
}

.memory-used {
  background-color: #5f8f8c;
  color: #6c757d;
  font-size: 1rem;
}

.storage-used {
  background-color: #c3bd90;
  color: #6c757d;
}

.progress-bar-title {
  font-size: 1rem;
  font-weight: bolder;
  position: absolute;
  text-align: center;
  line-height: 2rem; /* line-height should be equal to bar height */
  overflow: hidden;
  color: #6c757d;
  right: 0;
  left: 0;
}

.switch-button {
  user-select: none;
}

.link-gap {
  column-gap: 0.75rem;
  row-gap: 0.5rem;
}
</style>
